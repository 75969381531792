import {
  GET_USER_BY_ID,
  GET_USER_COURSES,
  GET_USER_COURSE_PROGRESS,
} from "GraphQl/Queries/User";
import Loader from "assets/animated-images/loader";
import React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  getCourseCertificate,
  getQuizScoresFromMoodle,
  getUserMoodleCourses,
} from "api/Course";
import { errorHandler } from "utils/toast";

export default function TraineeProgress(props) {
  const { traineeId } = props;
  const [moodleCourses, setMoodleCourse] = useState();
  const [traineeProgress, setTraineeProgress] = useState(null);
  const [courseIds, setCourseIds] = useState([]);
  const { data: CourseData } = useQuery(GET_USER_COURSES, {
    variables: { userId: traineeId },
  });
  const { data: TraineeData } = useQuery(GET_USER_BY_ID, {
    variables: {
      id: traineeId,
    },
  });

  const changeCertiNameOnDownload = async (url, filename) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  };

  const createError = () => {
    errorHandler("Certificate is not available for this course");
  };
  //getting moodle courses for user
  getUserMoodleCourses(traineeId).then(setMoodleCourse);

  //rebuild
  const { data: progressData } = useQuery(GET_USER_COURSE_PROGRESS, {
    variables: {
      courseIdArray: courseIds,
      userId: traineeId,
      courseIdArrayBig: courseIds,
      userIdBig: traineeId,
    },
  });
  useEffect(() => {
    //getting courses of a trainee
    const traineeCourseArray = (
      CourseData?.courses_users[0].enrollments || []
    ).map((course) => {
      return {
        course_id: course.course_array[0].id,
        course_name: course.course_array[0].full_name,
        is_moodle_course: course.course_array[0].is_moodle_course,
        moodle_course_id: course.course_array[0].is_moodle_course
          ? course.course_array[0].moodle_course_id
          : undefined,
        certificate: "",
        quizModules: [],
      };
    });

    setCourseIds(traineeCourseArray.map((item) => item.course_id));

    //getting/ extracting course modules required to complete a course
    traineeCourseArray.forEach((course) => {
      if (course.is_moodle_course) {
        (moodleCourses || []).forEach((mdlCourse) => {
          if (parseInt(mdlCourse.id) === parseInt(course.moodle_course_id)) {
            course.is_complete = mdlCourse.completed;
            course.percentage = (mdlCourse.progress || 0).toFixed(2);
            if (mdlCourse.completed)
              course.certificate = getCourseCertificate(
                traineeId,
                course.course_id
              );

            getQuizScoresFromMoodle(traineeId, course.course_id).then((res) => {
              course.quizModules = (res || []).map((quiz) => {
                return {
                  quizTitle: quiz.quiz_title,
                  quizAttemptPercentage: quiz.quiz_course,
                };
              });
            });
          }
        });
      } else {
        let completionCriteriaArr = [];
        progressData?.courses_course_completion_criteria.forEach((item) => {
          if (item.course_id === course.course_id) {
            completionCriteriaArr.push(item.mapping_id);
          }
        });

        //getting/extracting course modules that the user has completed
        let completedModules = [];
        let quizModulesAndScore = [];
        progressData?.courses_course_module_completion.forEach((item) => {
          if (item.course_id === course.course_id) {
            completedModules.push(item.mapping_id);
          }

          //getting quiz modules and their score
          let maxScoreQuiz = undefined;
          progressData?.courses_user_course_quiz_attempt.forEach(
            (quizModule) => {
              if (quizModule.course_id === course.course_id) {
                if (item.mapping_id === quizModule.mapping_id) {
                  //handling maximum quiz score in multiple attempts
                  if (
                    !maxScoreQuiz ||
                    maxScoreQuiz.mapping_id !== quizModule.mapping_id
                  ) {
                    maxScoreQuiz = {
                      mapping_id: quizModule.mapping_id,
                      attempt_percentage: quizModule.attempt_percentage,
                    };
                  }

                  maxScoreQuiz.attempt_percentage =
                    maxScoreQuiz.attempt_percentage >=
                    quizModule.attempt_percentage
                      ? maxScoreQuiz.attempt_percentage
                      : quizModule.quizAttemptPercentage;

                  if (
                    maxScoreQuiz.attempt_percentage >
                    quizModule.attempt_percentage
                  )
                    quizModulesAndScore.push({
                      quizTitle: item.section_completion_obj?.name || "NA",
                      quizAttemptPercentage:
                        maxScoreQuiz.attempt_percentage || "NA",
                    });
                }
              }
            }
          );
        });

        course.quizModules = quizModulesAndScore;

        //matching the modules ids to get completion percentage of the course
        let matchCount = 0;
        completionCriteriaArr.forEach((compModule) => {
          completedModules.forEach((module) => {
            if (compModule === module) matchCount = matchCount + 1;
          });
        });

        course.percentage = (
          (matchCount * 100) /
          completionCriteriaArr.length
        ).toFixed(2);

        //getting course completion data incase the trainee has completed the course via bulk upload or other means besides completing modules
        progressData?.courses_user_course_complete.forEach((item) => {
          if (item.course_id === course.course_id) {
            course.percentage = 100;
          }
        });

        //getting/ extracting certificate data
        progressData?.courses_certificates.forEach((item) => {
          if (item.course_id === course.course_id) {
            course.certificate = item.certificate_pdf_url;
          }
        });

        if (course.percentage === 100) {
          course.is_complete = true;
        } else {
          course.is_complete = false;
        }
      }
    });

    setTraineeProgress(traineeCourseArray);
  }, [progressData, CourseData, moodleCourses]);

  return traineeProgress === null ? (
    <Loader />
  ) : traineeProgress === false ? (
    <div className="text-xl font-semibold p-2 w-96 text-center">
      Couldn't fetch trainee progress
    </div>
  ) : (
    <div className="text-center p-3 ">
      <span className="text-2xl font-bold  font-poppins text-left">
        Trainee progress
      </span>
      {!traineeProgress.length ? (
        <div className="w-96 font-semibold text-gray-400">
          No information available
        </div>
      ) : (
        <ul className="w-full text-left ml-0 pl-0 mt-3 space-y-6">
          {traineeProgress.map((course) => (
            <li key={course.course_id}>
              <span className="font-poppins font-semibold">
                {course.course_name}
              </span>{" "}
              <ul className="">
                <li>
                  <span className="font-poppins font-medium">
                    Course Completed:
                  </span>{" "}
                  {course.is_complete ? "Yes" : "No"}
                </li>
                {course.quizModules?.length === 0 ? (
                  ""
                ) : (
                  <li className="font-poppins font-medium mt-2 ">Quizzes:</li>
                )}
                {(course.quizModules || []).map((quiz) => (
                  <li>
                    <span className="font-poppins font-normal">
                      {quiz.quizTitle}
                    </span>
                    {": "}
                    {quiz.quizAttemptPercentage}
                    {" (%)"}
                  </li>
                ))}
                {course.is_complete ? (
                  <li className="mt-4">
                    {course.certificate ? (
                      <a
                        onClick={() =>
                          changeCertiNameOnDownload(
                            course.certificate,
                            `${
                              TraineeData?.courses_users[0].name.replace(
                                " ",
                                "-"
                              ) || ""
                            }-${TraineeData?.courses_users[0].id || ""}`
                          )
                        }
                        className="btn-primary hover:text-white no-underline cursor-pointer"
                        target={"_blank"}
                      >
                        Download Certificate
                      </a>
                    ) : (
                      <button
                        onClick={createError}
                        className="btn-primary hover:text-white no-underline cursor-pointer"
                        target={"_blank"}
                      >
                        Download Certificate
                      </button>
                    )}
                  </li>
                ) : (
                  <li className="font-poppins font-medium">
                    Course Progress:{" "}
                    {isNaN(course.percentage) ? (
                      <i className="text-gray-400">Please Wait</i>
                    ) : (
                      course.percentage + " %"
                    )}
                  </li>
                )}
              </ul>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
